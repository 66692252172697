<template>
  <v-container class="header__bgcolor">
    <v-row class="d-flex align-center">
      <v-col cols="2" class="d-flex justify-start">
        <div>
          <v-img
            height="40"
            width="200"
            contain
            to="/"
            :src="`${getImgPath}${getLang.img}`"
            @dblclick="toggleVersion"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="8" class="px-10">
        <nav class="d-flex justify-space-between">
          <router-link
            class="router"
            v-for="router in getRouterJson"
            v-model="rout"
            :key="router.id"
            :to="router.to"
            @click="onSetActiveRouter(router)"
          >
            {{ router.captions[getLang.id].caption }}
          </router-link>
        </nav>
        <!-- {{ getRouterJson }} -->
      </v-col>
      <v-col cols="2" class="d-flex flex-row-reverse">
        <v-select
          v-model="selLang"
          :items="getLangs"
          item-title="caption"
          item-value="id"
          :label="getLang.title"
          @update:model-value="onSetLang"
          base-color="yellow"
          color="yellow"
          class="select"
        ></v-select>
        <!-- {{ getLang }} -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data: () => ({
    title: "",
    selLang: {},
    desc: [],
    selectedColor: "white",
    rout: "",
  }),
  methods: {
    ...mapMutations({
      toggleVersion: "toggleVersion",
      updateActiveRouter: "updateActiveRouter",
      updateLang: "updateLang",
    }),

    // async onGetRouter() {
    // },
    onGetSelectLang() {
      this.selLang = this.getLang;
    },
    onSetLang() {
      // this.selLang = this.getLang;
      console.log("this.selLang");
      console.log(this.selLang);
      this.updateLang(this.selLang);
      this.onGetSelectLang();
      console.log("this.selLang after");
      console.log(this.selLang);
    },
    onToggleVersion() {
      this.toggleVersion();
      console.log("this.getRouterJson");
      console.log(this.getRouterJson);
    },
    onSetActiveRouter(router) {
      console.log("MH1 this.$router");
      console.log(this.$router.currentRoute.value.fullPath);
      if (router == "start") {
        this.$router.push("/");
      } else this.updateActiveRouter(router);
      console.log("MH2 this.$router");
      console.log(this.$router.currentRoute.value.fullPath);
      this.$router.currentRoute.value.fullPath = "/";
    },
  },
  computed: {
    ...mapGetters([
      "getLang",
      "getLangs",
      "getImgPath",
      "getVersion",
      "getRouterJson",
      "getActiveRouterTitle",
    ]),
    getRouterDesc() {
      // return state.aboutDesc.filter(item => item.lang == store.state.selectLang.lang),
    },
  },
  mounted() {
    this.onGetSelectLang();
    this.onSetActiveRouter("start");
  },
};
</script>

<style lang="sass" scoped>
.router
	font-size: 18px
	color: grey
	text-decoration: none
	&:hover
		color: lightgrey

.router-link-exact-active
	color: yellow

.select
	max-width: 50%
	font-size: 14px

.v-input__details
	display: none !important

.v-field__append-inner
	display: none !important
</style>
